<template>
	<div>
		<transition name="slide-up">
			<div v-if="pageShow" class="popup">
				<!-- 密钥库  步骤一 -->
				<div class="popup_common">
					<img src="../../static/icon_erjym_guanbi@2x.png" alt="" class="popup_close" @click="togglePopup()">
					<b-container class="bv-example-row">
						<b-row class="text-center" align-h="center">
							<b-col cols="11" md="6" class="header_view" style="padding: 0;">
								<div class="popup_content">
									<p class="popup_content_tit">{{ $t('mps.my_paper_wallet') }}</p>
									<div class="d-none d-md-block">
										<div ref="printContent">
											<div class="zhiqianbao">
												<div class="zhiqianbao_logo">
													<img src="../../static/logo.png" alt=""
														class="zhiqianbao_logo_logo">
													<div></div>
													<p>{{ $t('mps.paper_wallet') }}</p>
												</div>
												<div class="zhiqianbao_right">
													<div class="zhiqianbao_right_one">
														<img src="../../static/icon_youxiang@2x.png" alt="">
														<p>support@myetherwallet.com</p>
													</div>
													<div class="zhiqianbao_right_one">
														<img src="../../static/icon_wangzhi@2x.png" alt="">
														<p>https://www.myetherwallet.com</p>
													</div>
												</div>
											</div>
											<!-- <div class="zhiqianbao2">
												<img src="../../static/pic_wddztubiao@2x.png" alt="" class="zhiqianbao2_img">
												<div>
													<p class="zhiqianbao2_p1">我的地址图标</p>
													<p class="zhiqianbao2_p2">发送到此钱包时，请始终查找该图标。请把你的纸钱包放在安全的 地方！</p>
												</div>
											</div>
											<div class="zhiqianbao_xian"></div> -->
											<div class="zhiqianbao2">
												<div style="width: 60%;">
													<p class="zhiqianbao2_p1">{{ $t('mps.my_wallet_address') }}</p>
													<p class="zhiqianbao2_p2">{{wallet.address}}</p>
												</div>
												<!-- <img src="../../static/pic_zqb_erweima@2x.png" alt="" style="margin-right: 0;margin-left: 10px;" class="zhiqianbao2_img"> -->
												<div class="qrcode-container2">
													<canvas ref="qrcodeCanvas"
														class="erweima_content_mid_left"></canvas>
												</div>
											</div>
											<div class="zhiqianbao2">
												<div style="width: 60%;">
													<p class="zhiqianbao2_p1" style="color: #FF445B;">{{ $t('mps.my_private_key') }}</p>
													<p class="zhiqianbao2_p2" style="color: #FF445B;">
														{{ $t('mps.private_key_warning') }}</p>
													<p class="zhiqianbao2_p2">{{wallet.privateKey}}</p>
												</div>
												<!-- <img src="../../static/pic_zqb_erweima@2x.png" alt="" style="margin-right: 0;margin-left: 10px;"  class="zhiqianbao2_img"> -->
												<div class="qrcode-container2 zhiqianbao2_img"
													style="margin-right: 0;margin-left: 10px;">
													<canvas ref="qrcodeCanvas2"></canvas>
												</div>
											</div>
										</div>
										<div class="zhiqianbao_xian"></div>
										<img src="../../static/pic_zqb_dbpt@2x.png" alt="" class="zhiqianbao2_yazi">
										<div class="zhiqianbao_content_buttons">
											<div class="popup_content_buttons_right" @click="printSection">{{ $t('mps.print') }}</div>
										</div>
									</div>
									<div class="d-block d-sm-none" style="max-height: 80vh;overflow-y: scroll;">
										<div class="zhiqianbao">
											<div class="zhiqianbao_logo">
												<img src="../../static/logo.png" alt="" class="zhiqianbao_logo_logo">
												<div></div>
												<p>{{ $t('mps.wallet_address') }}</p>
											</div>
										</div>
										<div class="zhiqianbao_right">
											<div class="zhiqianbao_right_one" style="margin-top: 15px;">
												<img src="../../static/icon_youxiang@2x.png" alt="">
												<p>support@myetherwallet.com</p>
											</div>
											<div class="zhiqianbao_right_one">
												<img src="../../static/icon_wangzhi@2x.png" alt="">
												<p>https://www.myetherwallet.com</p>
											</div>
										</div>
										<!-- <div class="zhiqianbao2">
											<div>
												<p class="zhiqianbao2_p1">我的地址图标</p>
												<p class="zhiqianbao2_p2">发送到此钱包时，请始终查找该图标。请把你的纸钱包放在安全的 地方！</p>
											</div>
										</div>
										<img src="../../static/pic_wddztubiao@2x.png" alt="" class="zhiqianbao2_img" style="margin:10px 0 0 0"> -->
										<div class="zhiqianbao_xian"></div>
										<div class="zhiqianbao2">
											<div>
												<p class="zhiqianbao2_p1">{{ $t('mps.my_wallet_address') }}</p>
												<p class="zhiqianbao2_p2">{{wallet.address}}</p>
											</div>
										</div>
										<!-- <img src="../../static/pic_zqb_erweima@2x.png" alt="" class="zhiqianbao2_img" style="margin:10px 0 0 0"> -->
										<div class="qrcode-container2 zhiqianbao2_img" style="margin:10px auto 0">
											<canvas ref="qrcodeCanvas3"></canvas>
										</div>
										<div class="zhiqianbao2">
											<div>
												<p class="zhiqianbao2_p1" style="color: #FF445B;">{{ $t('mps.my_private_key') }}</p>
												<p class="zhiqianbao2_p2" style="color: #FF445B;">
													{{ $t('mps.private_key_warning') }}</p>
												<p class="zhiqianbao2_p2"
													style="word-break: break-all;overflow-wrap: break-word">
													{{wallet.privateKey}}
												</p>
											</div>
										</div>
										<!-- <img src="../../static/pic_zqb_erweima@2x.png" alt="" class="zhiqianbao2_img" style="margin:10px 0 0 0"> -->
										<div class="qrcode-container2 zhiqianbao2_img" style="margin:10px auto 0">
											<canvas ref="qrcodeCanvas4"></canvas>
										</div>
										<div class="zhiqianbao_xian"></div>
										<img src="../../static/pic_zqb_dbpt@2x.png" alt="" class="zhiqianbao2_yazi">
										<div class="zhiqianbao_content_buttons">
											<div class="popup_content_buttons_right" @click="printSection">{{ $t('mps.print') }}</div>
										</div>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</transition>
		<canvas ref="qrcodeCanvas4"></canvas>
		<canvas ref="qrcodeCanvas3"></canvas>
	</div>
</template>

<script>
	import QRCode from 'qrcode'
	export default {
		name: 'MyImport',
		props: {
			pageShow: {
				type: Boolean,
				required: true,
				default: false
			}
		},
		data() {
			return {
				wallet: null,
			}
		},
		watch: {
			pageShow(newval, old) {
				console.log(old)
				if (newval) {
					this.$nextTick(() => {
						this.generateQRCode();
						this.generateQRCode2();
						this.generateQRCode3();
						this.generateQRCode4();
					})
				}
			}
		},
		created() {
			this.wallet = JSON.parse(localStorage.getItem("wallet"));
			console.log(this.wallet)
		},
		methods: {
			printSection() {
				this.convertCanvasToImage(this.$refs.qrcodeCanvas);
				this.convertCanvasToImage(this.$refs.qrcodeCanvas2);
				this.convertCanvasToImage(this.$refs.qrcodeCanvas3);
				this.convertCanvasToImage(this.$refs.qrcodeCanvas4);

				// 延时执行打印操作，确保图片加载完成
				setTimeout(() => {
					const printContent = this.$refs.printContent.innerHTML;
					const newWindow = window.open("", "_blank");
					newWindow.document.write(`
					                <html>
					                    <head>
					                        <title>打印</title>
					                        <style>
											/* 将打印样式内联，确保打印时生效 */
											@media print {
												.zhiqianbao {
													display: flex !important;
													justify-content: space-between !important;
													align-items: center !important;
													padding: 20px !important;
													margin-bottom: 20px !important;
												}
												
												.zhiqianbao_logo {
													display: flex !important;
													align-items: center !important;
												}
												.zhiqianbao_logo div {
													width: 2px !important;
													height: 20px !important;
													background-color: #0091FE !important;
													margin: 0 10px !important;
												}
												.zhiqianbao_logo_logo {
													width: 118px !important;
													height: 32 !important;
													margin-right: 10px !important;
												}
						
												.zhiqianbao_right {
													display: flex !important;
													flex-direction: column !important;
													justify-content: flex-start !important;
												}
						
												.zhiqianbao_right_one {
													display: flex !important;
													align-items: center !important;
													margin-bottom: 5px !important;
												}
						
												.zhiqianbao_right_one img {
													width: 20px !important;
													height: auto !important;
													margin-right: 10px !important;
												}
						
												.zhiqianbao_right_one p {
													margin: 0 !important;
													font-size: 14px !important;
												}
												.popup {
													position: static;
													width: 100%;
													height: auto;
													background-color: #ffffff;
													margin: 0;
													padding: 0;
												}
						
												.popup_content {
													padding: 20px;
												}
						
												.popup_close, .zhiqianbao2_yazi, .zhiqianbao_xian {
													display: none !important;
												}
						
												.qrcode-container2 {
													width: 150px;
													height: 150px;
													display: flex;
													justify-content: center;
													align-items: center;
												}
						
												.qrcode-container2 img {
													width: 100%;
													height: 100%;
												}
						
												.zhiqianbao2 {
													display: flex;
													align-items: flex-start;
												}
						
												.zhiqianbao2_p2 {
													word-break: break-word;
													overflow-wrap: break-word;
												}
											}
										</style>
					                    </head>
					                    <body>${printContent}</body>
					                </html>
					            `);
					newWindow.document.close();
					newWindow.focus();
					newWindow.print();
					newWindow.close();
				}, 500); // 延时500毫秒执行
			},
			convertCanvasToImage(canvas) {
				const img = document.createElement('img');
				img.src = canvas.toDataURL('image/png');
				canvas.parentNode.replaceChild(img, canvas);
			},
			generateQRCode() {
				var _this = this;
				const canvas = _this.$refs.qrcodeCanvas
				QRCode.toCanvas(canvas, _this.wallet.address, error => {
					if (error) console.error(error)
				})
			},
			generateQRCode2() {
				var _this = this;
				const canvas = _this.$refs.qrcodeCanvas2
				QRCode.toCanvas(canvas, _this.wallet.privateKey, error => {
					if (error) console.error(error)
				})
			},
			generateQRCode3() {
				var _this = this;
				const canvas = _this.$refs.qrcodeCanvas3
				QRCode.toCanvas(canvas, _this.wallet.address, error => {
					if (error) console.error(error)
				})
			},
			generateQRCode4() {
				var _this = this;
				const canvas = _this.$refs.qrcodeCanvas4
				QRCode.toCanvas(canvas, _this.wallet.privateKey, error => {
					if (error) console.error(error)
				})
			},
			togglePopup() {
				this.$emit('updatePageshow', !this.pageShow);
			},
		},
	}
</script>
<style scoped>
	@media print {

		/* 设置打印时的布局样式 */
		.popup {
			position: static;
			width: 100%;
			height: auto;
			background-color: #ffffff;
			margin: 0;
			padding: 0;
		}

		.popup_content {
			padding: 20px;
			/* 确保内容不贴边 */
		}

		/* 隐藏不需要打印的元素 */
		.popup_close,
		.zhiqianbao2_yazi,
		.zhiqianbao_xian {
			display: none !important;
		}

		/* 确保二维码和文字的布局保持一致 */
		.qrcode-container2 {
			width: 150px;
			height: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.qrcode-container2 img {
			width: 100%;
			height: 100%;
		}

		.zhiqianbao2 {
			display: flex;
			align-items: flex-start;
		}

		/* 确保文字换行显示 */
		.zhiqianbao2_p2 {
			word-break: break-word;
			overflow-wrap: break-word;
		}
	}
</style>
<style>
	.qrcode-container2 {
		width: calc(100px + 1vw);
		/* 容器宽度 */
		height: calc(100px + 1vw);
		/* 容器高度 */
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qrcode-container2>canvas {
		width: 100% !important;
		/* 覆盖二维码的默认宽度 */
		height: 100% !important;
		/* 覆盖二维码的默认高度 */
		background-size: cover !important;
		/* 确保二维码背景图像覆盖整个容器 */
	}

	.qrcode-container2>img {
		width: 100% !important;
		/* 覆盖二维码的默认宽度 */
		height: 100% !important;
	}
</style>
<style scoped lang="less">
	/* 弹出框的样式 */
	.popup {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		width: 100%;
		height: 100%;
		background-color: #F3F5FB;
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.popup_common {
			width: 100%;
			height: 1005;
		}
	}

	/* 过渡效果 */
	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	}

	.slide-up-enter,
	.slide-up-leave-to {
		transform: translateY(100%);
		opacity: 0;
	}

	.popup_close {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 1;
	}

	.popup_content {
		background-color: #ffffff;
		border-radius: 12px;
		padding: calc(10px + 0.5vw);

		.popup_content_tit {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
		}
	}

	.zhiqianbao {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: calc(10px + 1vw);

		.zhiqianbao_logo {
			display: flex;
			align-items: center;

			.zhiqianbao_logo_logo {
				width: 118px;
				height: 32px;
			}

			div {
				width: 1px;
				height: 20px;
				background-color: #0091FE;
				margin: 0 10px
			}

			p {
				font-size: calc(10px + 0.4vw);
				color: #1A183F;
			}
		}


	}

	.zhiqianbao_right_one {
		display: flex;
		align-items: center;
		margin-top: 5px;

		img {
			width: calc(10px + 1vw);
		}

		p {
			font-size: calc(10px + 0.4vw);
			color: #1A183F;
			margin-left: 10px;
		}
	}

	.zhiqianbao2 {
		display: flex;
		align-items: center;
		margin-top: calc(10px + 1vw);
		justify-content: space-between;

		.zhiqianbao2_p1 {
			font-size: calc(12px + 0.6vw);
			color: #1A183F;
			font-weight: 600;
			text-align: left;
		}

		.zhiqianbao2_p2 {
			font-size: calc(10px + 0.4vw);
			color: #1A183F;
			text-align: left;
			margin-top: 5px;
			word-wrap: break-word;
		}
	}

	.zhiqianbao2_img {
		width: calc(100px + 1vw);
		margin-right: calc(10px + 1vw);
	}

	.zhiqianbao_xian {
		width: 100%;
		height: 1px;
		margin-top: calc(10px + 1vw);
		background-color: #E3E5ED;
	}

	.zhiqianbao2_yazi {
		width: calc(110px + 1vw);
		margin-top: calc(10px + 1vw);
	}

	.zhiqianbao_content_buttons {
		display: flex;
		justify-content: center;
		margin: calc(15px + 1vw) 0 0 0;

		.popup_content_buttons_left {
			width: calc(120px + 1vw);
			padding: calc(5px + 0.4vw) 0;
			border: 1px solid #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #0091FE;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.popup_content_buttons_right {
			width: calc(120px + 1vw);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: calc(5px + 0.4vw) 0;
			background-color: #0091FE;
			font-size: calc(12px + 0.2vw);
			border-radius: 8px;
			color: #FFFFFF;
			margin-left: calc(12px + 0.2vw);
			cursor: pointer;
		}

		.defaul {
			background-color: #E3E5ED;
		}
	}

	@media print {
		.popup {
			/* 针对打印的样式调整 */
			background-color: #FFFFFF !important;
			/* 确保背景色正确 */
		}

		.qrcode-container2>img {
			width: 100% !important;
			height: 100% !important;
		}
	}
</style>