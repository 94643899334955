export default {
  ethPackage: "マイイーサウォレット",
  cancel: "キャンセル",
  add: "追加",
  tip: "ヒント",
  input_password: "パスワードを入力",
  please_enter_password: "パスワードを入力してください",
  back: "戻る",
  copy_success: "コピー成功",
  upload_keystore: "Keystoreファイルをアップロードし、パスワードを入力してください",
  no_mnemonic: "ニーモニックなし",
  file_parse_failed: "ファイルの解析に失敗しました。パスワードを確認してください",
  wallet_address_parse_failed: "ウォレットアドレスの解析に失敗しました。秘密鍵の信頼性を確認してください！",
  wallet_generation_failed: "ウォレットの生成に失敗しました",
  step_1: "1",
  step_2: "2",
  step_2_label: "ステップ2",
  input_password_to_unlock: "ウォレットをアンロックするためにパスワードを入力してください",
  next_step: "次のステップ",
  Accessmywallet:'財布へのアクセス',
  'Trusted by millions': '数百万人の信頼を得て',  
    'worldwide since 2015': '2015年から世界中で',  
    'Store': '保管',  
    'Ethereum': 'イーサリアム',  
    'safely': '安全に',  
    'Keep your Ethereum safe with features like the Trust Wallet Security Scanner and Encrypted Cloud Backup.': 'Trust Walletのセキュリティスキャナーや暗号化クラウドバックアップなどの機能を利用し、イーサリアムを安全に保管。',  
    'Stay in control': 'コントロールを握り続け',  
    'We secure your': 'お客様のイーサリアムウォレットを保護いたしますが、プライベートキーやシークレットフレーズはお客様のみが管理・アクセス可能です。',  
    'Buy, sell, and': '購入、売却、および',  
    'swap safely': '安全に交換',  
    'Convert USD, EUR': 'ドル、ユーロ、ポンド、その他100以上の法定通貨をイーサリアムにスムーズに変換。簡単に法定通貨に売却。交換など他の機能も利用できます。',  
    'Easy asset': '資産の管理が簡単',  
    'management': '管理',  
    'Compare and secure the': '信頼できる支払いプロバイダーから、様々な暗号通貨の最良のレートを比較し、安全に取得。',  
    'How to secure your Ethereum wallet': 'イーサリアムウォレットを安全に保つ方法',  
    'Always be skeptical of unsolicited messages or emails asking for your wallet information. Verify the source and never click on suspicious links, as they may be phishing attempts to steal your assets. Note that Trust Wallet will NEVER reach out asking for your private keys or secret phrase. If you’re ever in doubt, reach out to our support team.': 'ウォレット情報を求める不意のメッセージやメールには常に警戒を持ちてください。情報源を確認し、怪しいリンクをクリックしないでください。これはお客様の資産を盗むためのフィッシング攻撃かもしれません。Trust Walletは決してプライベートキーやシークレットフレーズを求めることはありません。疑問があれば、サポートチームに連絡をお願いください。',  
    'Frequently asked questions': 'よくある質問',  
    'Supporting all ERC-20 tokens': '全てのERC-20トークンをサポート',  
    'If it is on Ethereum': 'イーサリアムブロックチェーン上にあれば、MEWウォレットはサポートいたします。カスタムトークンを手動で追加する必要はありません。',  
    'Grab control of your future': '未来をコントロール',  
    'Create a new wallet': '新しいウォレットを作成',  
    'Get better at crypto': '暗号通貨をマスター',  
    'Level up your skills with security tips, industry insights, new and more!': 'セキュリティティップ、業界インサイト、その他新着情報でスキルをアップ！',  
    'Enter your email': 'メールアドレスを入力',  
    'Sign me up!': '登録！',  
    'Warning: Trading cryptocurrencies involves significant high risks.': '警告：暗号通貨の取引は高いリスクを伴います。',  
    'BTC Direct was rated a 4.5 out of 5 based on 10475 reviews': 'BTC Directは10475件のレビューに基づいて4.5/5の評価を獲得',  
    '© 2013 - 2024 - BTC Direct Europe B.V.': '© 2013 - 2024 - BTC Direct Europe B.V.',  
    'Sitemap': 'サイトマップ',  
    'Disclaimer': '免責条項',  
    'Terms of Service': 'サービス利用規約',  
    'Privacy Policy': 'プライバシーポリシー',  
    'Fiat onramp': '法定通貨オンランプ',  
    'Back up your wallet': 'ウォレットをバックアップ',  
    'Never share your private key': 'プライベートキーを決して共有しない',  
    'Keep your wallet and device software up to date': 'ウォレットとデバイスのソフトウェアを最新に保つ',  
    'Be cautious of phishing scams': 'フィッシング詐欺に注意',  
    'What is a Ethereum wallet?': 'イーサリアムウォレットとは？',  
    'Are Ethereum wallets free?': 'イーサリアムウォレットは無料ですか？',  
    'What is the safest Ethereum Wallet?': '最も安全なイーサリアムウォレットは？',  
    'Which app can I use to create my Ethereum wallet?': 'イーサリアムウォレットを作成するにはどのアプリを使用できますか？',
    'boots1': "複数の安全なバックアップを作成して資産を保護してください。Trust Walletはあなたの秘密鍵のコピーを管理または保有しませんが、バックアップを安全な場所に保管し、Trust Walletの暗号化クラウドバックアップ機能を利用して追加の保護層を提供することを考慮してください。",  
    'boots2': "あなたの秘密鍵（シードフレーズとも呼ばれます）はウォレットの主秘密鍵です。それを共有することは、資産の管理権を他の人に渡すようなものです。秘密鍵を秘密に保ち、オンラインまたはテキストで開示しないでください、ウォレットの安全性と完全性を維持するために。",  
    'boots3': "古いソフトウェアはハックや他のセキュリティリスクに弱いです。Trust Walletの更新（利用可能な場合）をインストールし、デバイスのソフトウェアを最新バージョンに定期的に更新して、資産の保護を助けます。",  
    'boots4': "ウォレット情報を要求する不意のメッセージや電子メールには常に警戒を持ちてください。送信元を確認し、怪しいリンクをクリックしないでください、それらは資産を盗もうと試みるフィッシング攻撃かもしれません。Trust Walletは決してプライベートキーやシードフレーズを要求しません。疑問があれば、サポートチームに連絡してください。",  
    'boots5': "イーサリアムウォレットは、イーサリアムを安全に購入、保管、管理できるデジタルツールです。イーサリアムを送受信できるだけでなく、購入、売却、交換などの機能にもアクセスできます。Trust Walletのようなイーサリアムウォレットを使用することで、7000万人以上のユーザーがいる安全な暗号通貨管理プラットフォームに加入します。",  
    'boots6': "はい、Trust Walletのダウンロードとインストールは無料です。しかし、イーサリアムの購入や送金などの取引はネットワーク料金を発生する場合があります。Trust Walletは初心者から経験豊富な暗号通貨愛好家まで、イーサリアムや他の暗号通貨を管理できるユーザーフレンドリーなプラットフォームを提供しています。",  
    'boots7': "安全なイーサリアムウォレットは、プライベートキーを完全に管理できり、資産の保護に役立つ機能を提供するウォレットです。Trust Walletは、これらの基準を満たすために安全なイーサリアムの保管所を提供することにコミットしています。Trust Walletのセキュリティスキャナーや暗号化クラウドバックアップなどの機能を利用することで、プライベートキーとシードフレーズを管理でき、あなたの資産にアクセスできるのはあなただけです。さらに、定期的ソフトウェアの更新と機密情報を慎重に扱うためのヒントは、Trust Walletはあなたと一緒にいて、あなたのイーサリアムを安全に保つ意味を持ちます。",  
    'boots8': "Trust Walletはイーサリアム（ETH）ウォレットを作成と管理できる信頼できるアプリケーションです。イーサリアム（ETH）を含む複数のブロックチェーンをサポートするマルチチェーンの自己保管ウォレットです。Android、Apple iOS、デスクトッププラットフォームで利用できるTrust Walletは、イーサリアム（ETH）を保管、支払、送金できるシームレスなプラットフォームを提供しており、世界中の数百万人の信頼を得ています。",
  components: {
    add_custom_token: "カスタムトークンを追加",
    enter_correct_address: "正しいコントラクトアドレスを入力してください",
    select_network: "ネットワークを選択",
    enter_contract_addressm: "コントラクトアドレスを入力してください",
    about_us: "私たちについて",
    advertise_with_us: "私たちと広告を出す",
    privacy: "プライバシー",
    terms: "利用規約",
    help_center: "ヘルプセンター",
    frequently_asked_questions: "よくある質問",
    join_community: "コミュニティに参加",
    buy_crypto: "暗号通貨を購入",
    swap_tokens: "トークンをスワップ",
    more_features: "さらに多くの機能",
    resources: "リソース",
    products: "製品",
    access_my_wallet: "私のウォレットにアクセス",
    go_to: "に行く",
    mew_mobile: "MEWモバイル",
    encrypt: "暗号化",
    ethereum_virtual_machine: "イーサリアム仮想マシン",
    select_software_wallet: "ソフトウェアウォレットを選択",
    keystore: "キーストア",
    mnemonic: "ニーモニック",
    private_key: "秘密鍵",
    save_wallet_warning: "ウォレットをオフラインで保存し、USBドライブにバックアップを取り、秘密鍵とニーモニックを紙に書き留めてください。メールやクラウドストレージに保存しないでください。",
    access_wallet_with_keystore: "キーストアファイルを使用してウォレットにアクセス",
    select_file: "ファイルを選択",
    input_password: "パスワードを入力",
    step_1: "ステップ1",
    select_keystore_file: "キーストアファイルを選択",
    select_keystore_file_warning: "ウォレットをアンロックするためのキーストアファイルを選択してください",
    not_recommended: "推奨しません",
    access_wallet: "ウォレットにアクセス",
    access_wallet_with_mnemonic: "ニーモニックを使用してウォレットにアクセス",
    input_phrase: "フレーズを入力",
    address_and_network: "アドレスとネットワーク",
    input_mnemonic: "ニーモニックを入力してください",
    input_mnemonic_order: "正しい順序でニーモニックを入力してください。",
    select_address_and_network: "アドレスとネットワークを選択",
    address: "アドレス",
    amount: "金額",
    network: "ネットワーク",
    access_wallet_with_private_key: "秘密鍵を使用してウォレットにアクセス",
    input_private_key: "秘密鍵を入力してください",
    please_enter_private_key: "秘密鍵を入力してください",
  },
  mps: {
    my_public_address: "資金を受け取るための私の公開アドレス",
    receive_token_instruction: "別のアカウントからTOKENを受け取るには、そのアカウントからこのアドレスにTOKENを送信してください。",
    copy: "コピー",
    my_paper_wallet: "私のペーパーウォレット",
    paper_wallet: "ペーパーウォレット",
    my_wallet_address: "私のウォレットアドレス",
    my_private_key: "私の秘密鍵",
    private_key_warning: "この秘密鍵を誰かと共有すると資金を失う可能性があります！秘密鍵は安全な場所に保管してください！",
    print: "印刷",
    settings: "設定",
    wallet_address: "ウォレットアドレス",
    max_wallets: "最大10個のウォレットを追加できます。",
    enter_wallet_address: "ウォレットアドレスを入力してください",
    confirm_add: "追加を確認",
    currency_setting: "通貨設定",
    usd: "米ドル",
    jpy: "日本円",
    normal_priority: "通常優先",
    higher_priority: "高優先",
    highest_priority: "最高優先",
    fifteen_minutes: "15分",
    wallet_address_exists: "ウォレットアドレスは既に存在します",
  },
  chdhl: {
    create_new_wallet: "新しいウォレットを作成",
    select_wallet_creation_method: "新しいウォレットを作成する方法を選択してください",
    already_have_wallet: "すでにウォレットをお持ちですか？",
    access_wallet: "ウォレットにアクセス",
    keystore_file: "キーストアファイル",
    keystore_warning: "オンラインでキーストアファイルを使用すると、資金を失うリスクが高まります。この方法でウォレットを作成することは推奨しません。",
    mnemonic_phrase: "ニーモニックフレーズ",
    mnemonic_warning: "オンラインでニーモニックを使用すると、資金を失うリスクが高まります。この方法でウォレットを作成することは推奨しません。",
    return_to_home: "ホームに戻る",
    not_recommended: "推奨しません",
    offline_storage_advice: "ウォレットをオフラインで保存し、USBドライブにバックアップを取り、秘密鍵とニーモニックを紙に書き留めてください。メールやクラウドストレージに保存しないでください！",
    enter_password: "パスワードを入力",
    download_file: "ファイルをダウンロード",
    step_3: "3",
    reenter_password: "パスワードを再入力してください",
    create_wallet: "ウォレットを作成",
    download_keystore_file: "キーストアファイルをダウンロード",
    important_info_before_download: "キーストアファイルをダウンロードする前に知っておくべき重要な情報",
    dont_lose_it: "失くさないで",
    be_careful: "注意してください、失くしたら回復できません。",
    do_not_share: "共有しないでください",
    phishing_warning: "このファイルを悪意のあるフィッシングサイトで使用すると、資金が盗まれます。",
    make_a_backup: "バックアップを作成",
    protect_it: "それを保護してください、いつか数百万ドルの価値があるかもしれません。",
    confirm_download: "ダウンロードを確認",
    step_3s: "ステップ3",
    well_done: "よくできました！",
    ready_to_use: "あなたは今、イーサリアムが提供するすべてを利用する準備ができています。オフライン設定でのみキーストアファイルを使用します。",
    create_another_wallet: "別のウォレットを作成",
    write_down_phrase: "フレーズを書き留める",
    verify_phrase: "フレーズを確認",
    update: "更新",
    write_them_down: "それらを書き留める",
    select_correct_words: "数字に従って正しい単語を選択し、余分な単語を入力してください。",
    verify: "確認",
    password_mismatch: "パスワードが一致しません",
    verification_failed: "確認に失敗しました",
    keystore_generation_failed: "キーストアファイルの生成に失敗しました",
  },
  help_detail: {
    search_articles: "記事を検索…",
    all_series: "すべてのシリーズ",
    introduction: "紹介",
    overview: "概要",
    about_mew: "MEWに関するすべての情報の紹介。",
    author: "著者",
    updated_week_ago: "1週間前に更新",
    description: "非管理型のオープンソースクライアントインターフェースで、ユーザーがイーサリアムブロックチェーンと直接対話し、暗号資産を完全に制御できるようにします。MEWは、ウェブウォレット、iOSおよびAndroid用のモバイルウォレットアプリ、ブラウザウォレット拡張機能として使用できます。",
    wallet_app: "ウォレットアプリ",
    mobile_wallet_guide: "モバイルMEWウォレットの使用ガイド",
    encryption: "暗号化",
    enkrypt_resources: "Enkryptウェブブラウザ拡張ウォレットのリソースとガイド。",
    security_privacy: "セキュリティとプライバシー",
    wallet_security: "MEWでウォレットを安全に保つ方法。",
    access_wallet: "ウォレットにアクセス",
    access_methods: "サポートされている方法を使用してMEW上のウォレットにアクセスする方法。",
    send_transaction: "取引を送信",
    send_transaction_guide: "MEWで取引を送信する方法",
    exchange: "交換",
    exchange_guide: "私たちの交換パートナーが提供するMEWでの交換方法。",
    tokens: "トークン",
    token_interaction: "MEW上のトークンと対話する方法。",
    decentralized_apps: "分散型アプリ",
    dapp_interaction: "MEWを使用してDAppsに接続し、対話する方法。",
    mew_offline: "MEWオフライン",
    offline_usage: "バージョン5でMEWをオフラインで使用する方法。MEWバージョン6はまだオフライン機能をサポートしていません。",
    networks_nodes: "ネットワークとノード",
    connect_networks: "異なるネットワークとノードに接続する方法。",
  },
  help_list: {
    and: "と",
    other: "他の",
    articles: "記事",
    return_to_basics: "基本に戻る：",
    mew_tips: "MEWからのヒント",
    exchange_difference: "取引所（Coinbase、Kraken、Gemini、Binanceなど）とMyEtherWalletの違いは何ですか？",
    get_started: "始める",
    create_wallet: "MEWを使用してイーサリアムウォレットを作成する方法",
    transfer_crypto: "BinanceからEnkryptまたはMEWウォレットに暗号通貨を転送する方法",
    create_cold_wallet: "コールドウォレット（ペーパーウォレットとも呼ばれる）を作成する方法",
    buy_sell_crypto: "MEWポートフォリオを使用して暗号通貨を購入および販売する方法",
    what_is_non_custodial_wallet: "非管理型ウォレットとは何ですか？",
    check_balance: "イーサリアムブロックチェーンで残高を確認する方法",
    explore_deeply: "深く探る",
    crypto_terms: "イーサリアム/暗号通貨コミュニティで一般的に使用される用語集",
    what_if_mew_fails: "MEWが失敗した場合はどうなりますか？",
    does_mew_support_btc: "MEWはビットコイン（BTC）や他の通貨をサポートしていますか？",
    what_is_eth_smart_contract: "イーサリアムスマートコントラクトとは何ですか？",
    error_window_blocked: "エラー：ウィンドウがブロックされました",
    ensure_unique_address: "MEWが異なる人に同じ公開アドレスを提供しないことを確認してください",
    submit_pull_request: "MEWのGitHubにプルリクエストを送信する方法",
    does_mew_have_api: "MyEtherWalletにはAPIがありますか？",
    mew_api_request_limit: "MEW APIに対して送信できるリクエストの数に制限はありますか？",
    use_mew_as_pwa: "MEWをプログレッシブWebアプリ（PWA）またはChromeアプリとして使用する",
  },
  index: {
    mew_team_advice: "MyEtherWalletチームからのアドバイスと回答",
    most_reputable_wallet: "最も信頼性が高く、フレンドリーで安全な暗号財布。",
    create_new_wallet: "新しいウォレットを作成",
    or: "または",
    access_my_wallet: "私のウォレットにアクセス",
    our_products_make_crypto_easier: "私たちの製品は暗号をより簡単にします",
    available_on_mobile_and_desktop: "モバイルおよびデスクトップブラウザで利用可能",
    mew_mobile_app: "MEWモバイルアプリ",
    stake_swap_manage_crypto: "いつでもどこでも暗号通貨をステーク、スワップ、および管理します。",
    security_like_hardware_wallet: "ハードウェアウォレットに匹敵するセキュリティでモバイル暗号通貨を保護します。",
    powerful_nft_management: "強力なNFT管理、オンチェーンブラウザなど。",
    want_to_enter_crypto: "暗号通貨の世界に入りたいですか？",
    mew_is_best_choice: "MEWがあなたの最良の選択です。",
    ethereum_ecosystem: "イーサリアムエコシステムは、全Web3で最大の開発者コミュニティを持っています。",
    trusted_ethereum_wallet: "MEWは最も信頼されているイーサリアムウォレットの1つです。",
    not_investment_advice: "投資アドバイスではありません。すべての決定はユーザー自身のリスクで行われます。",
    truly_own_your_crypto: "本当にあなたの暗号資産を所有してください。",
    self_custody_wallet: "MEWは自己保管型ウォレットであり、あなた自身が鍵を保持することを意味します。他の誰も（MEWチームでさえも）あなたの暗号通貨にアクセスできません。",
    buy_store_send_swap_tokens: "トークンを購入、保存、送信、スワップ",
    store_tokens: "トークンを保存",
    buy_sell: "購入および販売",
    swap: "スワップ",
    mew_supports_eth_and_erc20: "MEWはETHおよびすべてのERC-20トークンをサポートしています！",
    get_tokens: "トークンを取得",
    make_crypto_yours: "あなたの暗号通貨を本当にあなたのものにしましょう。",
    stake_your_eth: "ETHをステークしてイーサリアムブロックチェーンの報酬を得る。",
    be_your_own_bank: "自分自身の銀行になる",
    crypto_as_banking: "資金を貸し出す大手金融機関とは異なり、暗号通貨の世界ではあなたが銀行です。イーサリアムのセキュリティを自動的に保護することで報酬を得ます。",
    staking_made_easy: "ステーキングが簡単に",
    easy_staking_with_mew: "MEWでは、ウォレットから簡単に暗号通貨をステークし、すぐに報酬を得られます。",
    start_staking_now: "今すぐステークを開始",
    staking: "ステーキング",
    no_minimum_liquidity: "最低流動性のステーキング要件なし",
    annual_rate_up_to: "年利率は最大",
    powerful_wallet: "すべての暗号ニーズを満たす強力なウォレット。",
    store_all_your_nfts: "さまざまなチェーンからのすべてのNFTを安全に1か所に保管します。モバイルまたはデスクトップで。",
    access_web3_apps: "Web3アプリにアクセス。デスクトップでEnkryptを使用してお気に入りのWeb3アプリを使用するか、モバイルアプリでMEWを使用してお気に入りのWeb3アプリにアクセスします。",
    multi_chain_access: "マルチチェーンアクセス。分散型ネットワークに接続し、人気のあるネットワーク間で資産をブリッジします。",
    why_mew_is_good_choice: "なぜMEWが良い選択なのか？",
    crypto_expert_since_2015: "2015年からの暗号の専門家",
    first_trusted_wallet: "MEWは世界初の信頼できるイーサリアムおよびL2ウォレットの1つです。",
    private: "プライベート",
    no_tracking: "私たちは個人情報、アカウントアドレス、資産残高を追跡しません。",
    hardware_wallet_support: "ハードウェアウォレットのサポート",
    support_major_hardware_wallets: "MEWネットワーク製品スイートおよびEnkryptにおいて、LedgerおよびTrezorを含むすべての主要なハードウェアウォレットをサポートしています。",
    secure_transparent: "安全で透明",
    independent_audits: "HackenProofおよび以前のHackerOneバグバウンティプログラムで独立した監査を受けています。",
    self_custody: "自己保管",
    you_control_your_assets: "あなたがあなたの資産を制御します。あなたの鍵、あなたの暗号通貨。",
    easy_to_use: "使いやすい",
    start_in_minutes: "数分で開始でき、事前の知識は必要ありません。",
    arm_yourself_with_knowledge: "知識を武装してください。",
    new_to_crypto: "暗号通貨に初めて触れましたか？MEWtopiaを探索してください！",
    free_resources: "私たちの無料リソースライブラリを利用して、イーサリアムを最大限に活用する方法を学びましょう！",
    help_center: "ヘルプセンター",
    faq: "よくある質問",
    common_crypto_questions: "暗号通貨に関する一般的な質問",
    chat_with_support: "サポートとチャット",
    get_human_help: "人間の助けを得る",
    create_new_wallet: "新しいウォレットを作成",
    ethereum_eth: "イーサリアム（ETH）",
    ondo: "オンド（ONDO）",
    unibot: "ユニボット（UNIBOT）",
    busd: "バイナンスUSD（BUSD）",
    icx: "ICON（ICX）",
    grt: "ザ・グラフ（GRT）",
    aurora: "オーロラ（AURORA）",
    root: "ルートネットワーク（ROOT）",
    lime: "IMEラボ（LIME）",
  },
  package: {
    ethereum: "イーサリアム",
    buy_store_send: "購入、保存、送信",
    usd: "米ドル",
    swap_tokens: "トークンをスワップ",
    mew_supports_eth_and_erc20: "MEWはETHとすべてのERC-20をサポートしています",
    make_your_crypto_yours: "あなたの暗号通貨を本当にあなたのものにしましょう。",
    tokens: "トークン！",
    cryptocurrency: "暗号通貨。",
    meet_all_your_crypto_needs: "すべての暗号ニーズを満たします。",
    why_mew_is_good_choice: "なぜMEWが良い選択なのか？",
    buy_hardware_wallet: "ハードウェアウォレットを購入",
    portfolio_value: "ポートフォリオの価値",
    wallet: "ウォレット",
    send: "送信",
    settings: "設定",
    logout: "ログアウト",
    other_apps: "他のアプリ",
    apps: "アプリ",
    nft_manager: "NFTマネージャー",
    exchange: "交換",
    stake: "ステーク",
    received: "受信",
    buy_sell: "購入と販売",
    contract: "契約",
    info: "情報",
    new_feature_stake_any_amount_of_eth: "新機能：任意の量のETHをステーク、Coinbaseが提供",
    stake_eth_no_minimum: "最低要件なしでETHをステークし、最大4％の年利を得る。いつでもステーク解除可能。",
    stake_now: "今すぐステーク",
    advertise_with_us: "私たちと広告を出す",
    network: "ネットワーク",
    block_height: "ブロック高さ：",
    total_assets: "総資産：",
    add_custom_token: "カスタムトークンを追加",
    send: "送信",
    delete: "削除",
    transaction_history: "取引履歴",
    transfer_out: "転送",
    transfer_in: "転入",
    no_transactions: "まだ取引はありません",
    are_you_sure_logout: "本当にログアウトしますか？",
    are_you_sure_delete_token: "現在のトークンを削除しますか？",
    tx_hash: "TXハッシュ",
    transaction_time: "取引時間",
    from: "から",
    to: "へ",
    amount: "金額",
    fee: "手数料",
    token: "トークン",
    hold: "保有",
    price: "価格",
    years_ago: "年前",
    months_ago: "ヶ月前",
    days_ago: "日前",
    hours_ago: "時間前",
    minutes_ago: "分前",
    seconds_ago: "秒前",
    failed_to_get_height: "高さの取得に失敗しました",
  },
  send: {
    line1: "なぜMEWが",
    line2: "良い選択なのか？",
    line3: "操作のヒント",
    line4: "確認",
  },
  zhensend: {
    balance: "残高：",
    max_limit: "最大限度",
    your: "あなたの",
    low_balance: "残高が低すぎます",
    transaction_fee: "各取引には少額が必要です",
    cannot_send: "を実行するために。交換するトークンがあっても、",
    near_zero_balance: "残高がほぼゼロの場合、アカウントに資金を追加するまで何も送信できません。",
    enter_address: "転送先アドレスを入力してください",
    fee: "取引手数料",
    total: "合計：",
    insufficient_fee: "ネットワーク手数料を支払うには不十分です。",
    custom: "カスタム",
    max_fee: "最大手数料",
    max_tip: "最大チップ",
    send_transaction: "取引を送信",
    zero_balance: "トークン残高は0です〜",
    enter_amount: "転送金額を入力してください",
    insufficient_token_balance: "トークン残高が不足しています",
    transaction_cost: "取引手数料は",
    transaction_success: "取引成功",
    insufficient_gas: "GASが不足しています",
    transaction_failed: "取引に失敗しました。入力内容を確認してください",
    gas_limit_failed: "Gas Limitの見積もりに失敗しました",
    token_amount_prompt: "トークンの数量を入力してください"
  }
};
