import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en';
import zh from './locales/zh';
import ja from './locales/ja';
import ko from './locales/ko';
import ru from './locales/ru';

Vue.use(VueI18n);

const messages = {
	en,
	zh,
	ja,
	ko,
	ru
};

const i18n = new VueI18n({
	locale: 'zh', // 默认语言
	fallbackLocale: 'zh', // 回退语言
	messages, // 语言包
});

export default i18n;